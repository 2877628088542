import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import GreenSquares from './components/GreenSquares';
import StravaConnect from './components/StravaConnect';

function AthleteRoute() {
  const [contributions, setContributions] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost';
    const port = isLocalhost ? '8000' : window.location.port;
    const hostname = isLocalhost ? window.location.hostname : `api.${window.location.hostname}`;
    const apiUrl = `${window.location.protocol}//${hostname}:${port}/api/athlete/${id}/activities`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setContributions(data);
      })
      .catch(error => console.error('Error fetching activities:', error));
  }, [id]);

  return (
    <div className="athlete-content">
      <h1>Stay active, fill this grid 💪</h1>
      <div className="green-squares-wrapper">
        <div className="green-squares-container">
          <GreenSquares contributions={contributions} />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <div className="scrollable-container">
          <Routes>
            <Route path="/athlete/:id" element={<AthleteRoute />} />
            <Route path="*" element={<StravaConnect />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;