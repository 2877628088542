import React from 'react';

const StravaConnect: React.FC = () => {
    const stravaOAuthUrl = 'https://www.strava.com/oauth/authorize';
    const clientId = '131843';
    const isLocalhost = window.location.hostname === 'localhost';
    const port = isLocalhost ? '8000' : window.location.port;
    const hostname = isLocalhost ? window.location.hostname : `api.${window.location.hostname}`;
    const redirectUri = `${window.location.protocol}//${hostname}:${port}/strava_oauth_redirect`;
    const scope = 'read,activity:read';

    const fullOAuthUrl = `${stravaOAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;

    return (
        <a href={fullOAuthUrl}>
            <img
                src="/btn_strava_connectwith_orange.svg"
                alt="Connect with Strava"
                style={{ cursor: 'pointer' }}
            />
        </a>
    );
};

export default StravaConnect;
