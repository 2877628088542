import React from 'react';
import { Tooltip } from 'react-tooltip';

interface SquareProps {
    contributions: number;
    date: string;
    getColor: (count: number) => string;
}

const Square: React.FC<SquareProps> = ({ contributions, date, getColor }) => {
    const backgroundColor = getColor(contributions);
    const borderColor = contributions === 0 ? '#d0d7de' : backgroundColor;

    const formattedDate = date
        ? new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            timeZone: 'UTC',
        })
        : '';

    const tooltipContent = date
        ? `${contributions} ${contributions === 1 ? 'activity' : 'activities'} on ${formattedDate}`
        : '';

    return (
        <td
            data-tooltip-id="contribution-tooltip"
            data-tooltip-content={tooltipContent}
            style={{
                width: '10px',
                height: '10px',
                backgroundColor,
                border: `1px solid ${borderColor}`,
                borderRadius: '2px',
            }}
        />
    );
};

interface Contribution {
    date: string;
    count: number;
}

interface GreenSquaresProps {
    contributions: Contribution[];
}

const GreenSquares: React.FC<GreenSquaresProps> = ({ contributions }) => {
    const sortedContributions = [...contributions].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    // Calculate quartiles
    const counts = sortedContributions.map(c => c.count).filter(count => count > 0);
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);
    const step = (maxCount - minCount) / 4;
    const q1 = minCount + step;
    const q2 = minCount + 2 * step;
    const q3 = minCount + 3 * step;

    const getColor = (count: number) => {
        if (count === 0) return '#ebedf0';
        if (count <= q1) return '#9be9a8';
        if (count <= q2) return '#40c463';
        if (count <= q3) return '#30a14e';
        return '#216e39';
    };

    const grid: Array<Array<{ count: number; date: string }>> = Array(7)
        .fill(null)
        .map(() => Array(53).fill({ count: 0, date: '' }));

    // Fill the grid with dates for the last year
    const DAY_IN_MS = 24 * 60 * 60 * 1000;
    const today = new Date();
    const currentSunday = new Date(today);
    currentSunday.setDate(today.getDate() + (7 - today.getDay()) % 7);
    console.log(currentSunday);

    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    const monthLabels: { [key: number]: string } = {};

    for (let d = new Date(oneYearAgo); d <= today; d.setDate(d.getDate() + 1)) {
        let weekOfYear = Math.floor((currentSunday.getTime() - d.getTime()) / (7 * DAY_IN_MS));
        if (d.getDay() === 0) {
            weekOfYear--;
        }
        let dayOfWeek = (d.getDay() + 1) % 7;

        if (weekOfYear < 53) {
            grid[dayOfWeek][52 - weekOfYear] = { count: 0, date: d.toISOString().split('T')[0] };

            // Store the first day of each month
            if (d.getDate() === 1) {
                monthLabels[52 - weekOfYear] = d.toLocaleString('default', { month: 'short' });
            }
        }
    }

    // Update the grid with actual contributions
    sortedContributions.forEach(({ date, count }) => {
        const d = new Date(date);
        let weekOfYear = Math.floor((currentSunday.getTime() - d.getTime()) / (7 * DAY_IN_MS));
        if (d.getDay() === 0) {
            weekOfYear--;
        }
        let dayOfWeek = (d.getDay() + 1) % 7;

        if (weekOfYear < 53) {
            grid[dayOfWeek][52 - weekOfYear] = { count, date };
        }
    });

    return (
        <>
            <table style={{ borderSpacing: '3px', minWidth: '910px' }}>
                <tbody>
                    {grid.map((week, dayIndex) => (
                        <tr key={dayIndex}>
                            {week.map((day, weekIndex) => (
                                <Square
                                    key={`${dayIndex}-${weekIndex}`}
                                    contributions={day.count}
                                    date={day.date}
                                    getColor={getColor}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: 'flex', minWidth: '910px', position: 'relative', height: '20px', marginTop: '4px' }}>
                {Object.entries(monthLabels).map(([weekIndex, month]) => (
                    <div
                        key={weekIndex}
                        style={{
                            position: 'absolute',
                            left: `${Number(weekIndex) * 17}px`,
                            fontSize: '12px'
                        }}
                    >
                        {month}
                    </div>
                ))}
            </div>
            <Tooltip id="contribution-tooltip" />
        </>
    );
};

export default GreenSquares;